import { getEnv } from "./env";

// for DEBUG ops
export const DEBUG_EMAIL = "njdayuk@icloud.com";
export const DEBUG_IP = "86.22.225.132";

// local dev: don't use subdomains
// cloud env: substitute client subdomain
export const getAppUrl = (clientSubdomain) => {
  if (process.env.NODE_ENV === "development") return "http://localhost:3000";
  return {
    sandbox: `https://${clientSubdomain ? `${clientSubdomain}.` : ""}sandbox.app.outcider.net`,
    staging: `https://${clientSubdomain ? `${clientSubdomain}.` : ""}staging.app.outcider.net`,
    production: `https://${clientSubdomain ? `${clientSubdomain}.` : ""}unifi.media`,
  }[getEnv()];
};

// same as `getAppUrl` except sandbox used for local dev so that links work
// rest API amplify apps have rewrites manually setup e.g.
// /rss/<*> -> https://xxx.execute-api.eu-west-2.amazonaws.com/production/rss/<*>
export const getPublicApiUrlPrefix = (clientSubdomain) => {
  if (process.env.NODE_ENV === "development") return "https://sandbox.app.outcider.net";
  return getAppUrl(clientSubdomain);
};

export const SENTRY_DSN = "https://1e1a33639691416b8bc01111422b01f4@o272098.ingest.sentry.io/5754154";
export const COOKIE_EXPIRY = 365;

// thumbor
export const THUMBOR_KEY = "jJAZNTSSva3zAuS49hVTMX6gujNt8XAd";
export const THUMBOR_SERVER = "https://images.outcider.net";

// metasearch
export const METASEARCH_URL = "http://metabase.moreover.com/api/v10/searchArticles";
export const METASEARCH_KEY = "167476c1b178492ab954f23db7250c70";

// metafilters
const METAFILTERS_KEYS = {
  sandbox: "c331eb1a09964ed8a0e953524a0eed4d",
  staging: "476d5a63911743c4b9e516b523f0b1cb",
  production: "1725eb5c1bd74e3dac7efb3a473fcbd7",
};
export const METAFILTERS_URL = "https://admin-metabase.moreover.com/api/v2/filters";
export const METAFILTERS_ARTICLES_URL = "https://metabase.moreover.com/api/v10/articles";
export const METAFILTERS_USER = "Outcider";
export const METAFILTERS_PASS = "sRavg9R4iSurTd!";
export const METAFILTERS_KEY = METAFILTERS_KEYS[getEnv()];
export const METAFILTERS_PREFIX = `AWS-${getEnv().toUpperCase()}`;

// metabase media types
export const METABASE_NEWS_MEDIA_TYPES = ["News", "Print"]; // source ranks 1-5
export const METABASE_SOCIAL_MEDIA_TYPES = [
  // source ranks 1-10
  "Blog",
  "Microblog",
  "Social Video",
  "Social Photo",
  "Social Network",
  "Podcast",
  "Review",
  "Wiki",
  "Comment",
  "Q&A",
  "Forum",
  "Classified",
];
export const METABASE_OTHER_MEDIA_TYPES = ["Broadcast"]; // no source ranks

// languages we can receive results in
export const METABASE_LANGUAGES = [
  "Afrikaans",
  "Albanian",
  "Amharic",
  "Arabic",
  "Armenian",
  "Azerbaijani",
  "Basque",
  "Belarusian",
  "Bengali",
  "Bosnian",
  "Bulgarian",
  "Burmese",
  "Catalan",
  "Central Khmer",
  "Chinese",
  "Croatian",
  "Czech",
  "Danish",
  "Dutch",
  "English",
  "Esperanto",
  "Estonian",
  "Faroese",
  "Filipino",
  "Finnish",
  "French",
  "Galician",
  "Georgian",
  "German",
  "Greek",
  "Gujarati",
  "Hebrew",
  "Hindi",
  "Hungarian",
  "Icelandic",
  "Indonesian",
  "Irish",
  "Italian",
  "Japanese",
  "Kannada",
  "Kazakh",
  "Kinyarwanda",
  "Korean",
  "Kurdish",
  "Lao",
  "Latvian",
  "Lithuanian",
  "Luxembourgish",
  "Macedonian",
  "Malagasy",
  "Malay",
  "Malayalam",
  "Maltese",
  "Marathi",
  "Mongolian",
  "Nepali",
  "Norwegian",
  "Oriya",
  "Persian",
  "Polish",
  "Portuguese",
  "Pushto",
  "Romanian",
  "Romansh",
  "Russian",
  "Samoan",
  "Serbian",
  "Shona",
  "Sinhala",
  "Slovak",
  "Slovenian",
  "Somali",
  "Spanish",
  "Swahili",
  "Swedish",
  "Tajik",
  "Tamil",
  "Telugu",
  "Thai",
  "Turkish",
  "Ukrainian",
  "Urdu",
  "Uzbek",
  "Vietnamese",
  "Welsh",
  "Zulu",
];

// https://docs.aws.amazon.com/translate/latest/dg/what-is.html#what-is-languages
// mapping of AWS translate language codes to the names as we receive from metabase
// (switched to using Google translate now but list is very similar)
export const TRANSLATE_LANGUAGES = {
  af: "Afrikaans",
  sq: "Albanian",
  am: "Amharic",
  ar: "Arabic",
  hy: "Armenian",
  az: "Azerbaijani",
  // "Basque",
  // "Belarusian",
  bn: "Bengali",
  bs: "Bosnian",
  bg: "Bulgarian",
  // "Burmese",
  ca: "Catalan",
  // "Central Khmer",
  zh: "Chinese",
  hr: "Croatian",
  cs: "Czech",
  da: "Danish",
  nl: "Dutch",
  en: "English",
  // "Esperanto",
  et: "Estonian",
  // "Faroese",
  tl: "Filipino",
  fi: "Finnish",
  fr: "French",
  // "Galician",
  ka: "Georgian",
  de: "German",
  el: "Greek",
  gu: "Gujarati",
  he: "Hebrew",
  hi: "Hindi",
  hu: "Hungarian",
  is: "Icelandic",
  id: "Indonesian",
  // "Irish",
  it: "Italian",
  ja: "Japanese",
  kn: "Kannada",
  kk: "Kazakh",
  // "Kinyarwanda",
  ko: "Korean",
  // "Kurdish",
  // "Lao",
  lv: "Latvian",
  lt: "Lithuanian",
  // "Luxembourgish",
  mk: "Macedonian",
  // "Malagasy",
  ms: "Malay",
  ml: "Malayalam",
  mt: "Maltese",
  // "Marathi",
  mn: "Mongolian",
  // "Nepali",
  no: "Norwegian",
  // "Oriya",
  fa: "Persian",
  pl: "Polish",
  pt: "Portuguese",
  // "Pushto",
  ro: "Romanian",
  // "Romansh",
  ru: "Russian",
  // "Samoan",
  sr: "Serbian",
  // "Shona",
  si: "Sinhala",
  sk: "Slovak",
  sl: "Slovenian",
  so: "Somali",
  es: "Spanish",
  sw: "Swahili",
  sv: "Swedish",
  // "Tajik",
  ta: "Tamil",
  // "Telugu",
  th: "Thai",
  tr: "Turkish",
  uk: "Ukrainian",
  ur: "Urdu",
  uz: "Uzbek",
  vi: "Vietnamese",
  cy: "Welsh",
  // "Zulu",
};

// languages from either metabase or translate list which are RTL
// https://lingohub.com/academy/best-practices/rtl-language-list
export const RTL_LANGUAGES = ["Arabic", "Hebrew", "Persian", "Pushto", "Urdu"];

// languages from either metabase or translate list which do not use spaces to separate words
export const NO_SPACES_LANGUAGES = ["Burmese", "Central Khmer", "Chinese", "Japanese", "Lao", "Thai"];

// languages supported by comprehend, https://docs.aws.amazon.com/comprehend/latest/dg/supported-languages.html
// metasearch requires language name, metafilters works with name or code, comprehend requires code
export const COMPREHEND_LANGUAGES = {
  de: "German",
  en: "English",
  es: "Spanish",
  it: "Italian",
  pt: "Portuguese",
  fr: "French",
  ja: "Japanese",
  ko: "Korean",
  hi: "Hindi",
  ar: "Arabic",
  zh: "Chinese", // only simplified supported
};

// get word boundary based on whether language uses spaces to separate words
export const languageWordBoundary = (language) => (!NO_SPACES_LANGUAGES.includes(language) ? "\\b" : "");

export const METABASE_COUNTRIES = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "British Virgin Islands",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curacao",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "DR Congo",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroes",
  "Federated States of Micronesia",
  "Fiji",
  "Finland",
  "France",
  "French Polynesia",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "North Korea",
  "Northern Mariana Islands",
  "Norway",
  "Occupied Palestinian Territory",
  "Oman",
  "Pakistan",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Viet Nam",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

// social 360
export const SOCIAL360_URL = "https://api.s360.signal-ai.com/article/search";
export const SOCIAL360_METADATA_URL = "https://api.s360.signal-ai.com/article/inputs";
export const SOCIAL360_CHART_URL = "https://api.s360.signal-ai.com/graph/es/render";
export const SOCIAL360_TOKEN = "33d7aba2dd9f67a6d4926c98393e5afd5f0422de";
export const SOCIAL360_SYNC_TOKEN = "f263da833b744417ffcf642d330d3636a6d55e65";
export const SOCIAL360_AUTHOR_GROUPS_KEY = "data/social360-author-groups.json";

// media types that we know how to handle the contents of, mapped to ids returned by S360 api
export const SOCIAL360_MEDIA_TYPES = {
  2: "Twitter",
  22: "Facebook",
  25: "LinkedIn",
  36: "Hansard",
  47: "Instagram",
  49: "TikTok",
};

// list provided by S360, mapped to language codes returned by S360 api
export const SOCIAL360_LANGUAGES = {
  af: "Afrikaans",
  an: "Aragonese",
  ar: "Arabic",
  ast: "Asturian",
  be: "Belarusian",
  br: "Breton",
  ca: "Catalan",
  bg: "Bulgarian",
  bn: "Bengali",
  cs: "Czech",
  cy: "Welsh",
  da: "Danish",
  de: "German",
  el: "Greek",
  en: "English",
  es: "Spanish",
  et: "Estonian",
  eu: "Basque",
  fa: "Persian",
  fi: "Finnish",
  fr: "French",
  ga: "Irish",
  gl: "Galician",
  gu: "Gujarati",
  he: "Hebrew",
  hi: "Hindi",
  hr: "Croatian",
  ht: "Haitian",
  hu: "Hungarian",
  id: "Indonesian",
  is: "Icelandic",
  it: "Italian",
  ja: "Japanese",
  km: "Khmer",
  kn: "Kannada",
  ko: "Korean",
  lt: "Lithuanian",
  lv: "Latvian",
  mk: "Macedonian",
  ml: "Malayalam",
  mr: "Marathi",
  ms: "Malay",
  mt: "Maltese",
  ne: "Nepali",
  nl: "Dutch",
  no: "Norwegian",
  oc: "Occitan",
  pa: "Punjabi",
  pl: "Polish",
  pt: "Portuguese",
  ro: "Romanian",
  ru: "Russian",
  sk: "Slovak",
  sl: "Slovene",
  so: "Somali",
  sq: "Albanian",
  sr: "Serbian",
  sv: "Swedish",
  sw: "Swahili",
  ta: "Tamil",
  te: "Telugu",
  th: "Thai",
  tl: "Tagalog",
  tr: "Turkish",
  uk: "Ukrainian",
  ur: "Urdu",
  vi: "Vietnamese",
  wa: "Walloon",
  yi: "Yiddish",
  "zh-cn": "Chinese (Simplified)",
  "zh-tw": "Chinese (Traditional)",
};

// intercom
export const INTERCOM_APP_ID = "mkkwzl7l";
export const INTERCOM_API_KEY = "dG9rOjAzMWQ0NGVjX2FmMGZfNGY0NV9hM2UzX2VkZDU2Y2UwNzYwMjoxOjA=";
export const INTERCOM_TOOLTIP_TAG_ID = "8269969"; // id of "out-tooltips" tag

// openai
export const OPENAI_KEY = "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";
